import React from 'react';
import { Container } from 'react-bootstrap'

const Covid = () => {
    return <Container>
        <h1 className="text-center mb-5">COVID-19</h1>
        <p class="text-justify">
            Corona Virus disease (COVID-19) originated in China and quickly spread to all continents affecting most countries
            in the world and was declared a pandemic by World Health Organisation on 11th March 2020. COVID 19 is similar to 
            Flu (influenza) pandemic in 1918-1920. Symptoms can include fever, body aches, headache, cough, difficulty breathing,
            sore throat, loss of smell, nausea, vomiting, diarrhea, etc. 95% of patients will develop symptoms within 2-7 days,
            97% within 5-14 days– this is the reasoning behind a 14-day quarantine period. COVID-19 is a new disease, so 
            there is no existing immunity in our community. This means that COVID-19 could spread widely and quickly. We 
            sincerely advise you to avoid cramped spaces, crowded areas, and close conversations with other people. Wearing a mask, 
            practice social distancing, and hand hygiene are essential measures to prevent COVID19. 
        </p>
        <p class="text-justify">
            The Spanish flu, also known as the 1918 flu pandemic, was a deadly influenza pandemic that lasted for two years. 
            It was estimated that nearly 50 crores of people or one-third of the world’s population became infected with this virus 
            and killed nearly 5 crores of people. Bharat was under the oppressive British rule during the flu pandemic. British didn’t
            care about our people’s traditions, cultures, health, social and financial situations. Bharat was the worst affected country 
            the world with 5 crore people infected from the virus and nearly 1.2 crores died from the disease. One hundred years later, 
            the situation is not significantly different from the one in 1918. Unlike Humans, Coronavirus does not discriminate on the 
            basis of age, gender, race, religion, caste, education, money, social status, etc. As of 1st August 2020, nearly 17 lakh 
            people were infected with COVID19 and 36000 people lost their lives. 
        </p>
        <p class="text-justify">
            Most people (about 80%) recover from the disease without needing hospital treatment. Around 1 out of every 5 people who get COVID-19 becomes seriously ill and develops difficulty breathing. Older people, and those with underlying medical problems like high blood pressure, heart and lung problems, diabetes, or cancer, are at higher risk of developing a serious illness.  However, anyone can catch COVID-19 and become seriously ill.  People of all ages who experience fever and/or cough associated with difficulty breathing/shortness of breath, chest pain/pressure, or loss of speech or movement should seek medical attention immediately. If possible, it is recommended to call the health care provider or facility first, so the patient can be directed to the right clinic.
        </p>
        <p class="text-justify">
            If you have minor symptoms, such as a slight cough or a mild fever, there is generally no need to seek medical care. Stay at home, self-isolate, and monitor your symptoms. Follow national guidance on self-isolation.
            Seek immediate medical care if you have difficulty breathing or pain/pressure in the chest. If possible, call your health care provider in advance, so he/she can direct you to the right health facility.
        </p>
    </Container>;
}

export default Covid;