import React, { Component } from 'react';
import { Link } from 'react-router-dom';
 
import { withFirebase } from '../Firebase';
import {PASSWORD_FORGET} from '../../constants/routes';
import {Container, Button, Row, Col, Alert} from 'react-bootstrap'
 
const PasswordForgetPage = () => (
  <Container className="text-center">
    
    <Row>
      <Col md={{ span: 4, offset: 4 }} >
      <h2>Forgot Password?</h2>
      <PasswordForgetForm />
      </Col>
    </Row>
  </Container>
);
 
const INITIAL_STATE = {
  email: '',
  error: null,
};
 
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE, message:"", variant:"" };
  }
 
  onSubmit = event => {
    const { email } = this.state;
 
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        const message = "please check your email for a confirmation link that will reset your password"
        this.setState({ ...INITIAL_STATE, message });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, error } = this.state;
 
    const isInvalid = email === '';
 
    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
          className="form-control"
        />
        <br />
        <Button disabled={isInvalid} type="submit">
          Reset My Password
        </Button>
        {this.state.message && <Alert variant="success mt-3"><b>{this.state.message}</b></Alert>}
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
 
const PasswordForgetLink = () => (
  <p>
    <Link to={PASSWORD_FORGET}>Reset Password?</Link>
  </p>
);
 
export default PasswordForgetPage;
 
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
 
export { PasswordForgetForm, PasswordForgetLink };