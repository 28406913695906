import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
 
import { withFirebase } from '../Firebase';
import ROUTES from '../../constants/routes';
import { Col, Button } from 'react-bootstrap'
import { PasswordForgetLink } from '../PasswordForget';
import ls from 'local-storage';
 
const SignInPage = () => (
  <Col className="text-center" md={{ span: 4, offset: 4 }}>
    <h1>SignIn</h1>
    <SignInForm />
    <ul className="text-left">
    <li> Your email ID is your user ID</li>
      <li>Please check your email for the default password</li>
  <li>To reset your password use the above 'Reset Password' link</li>
  <li>Doctor/Volunteer will have to wait till credentials are verified, revisit after 24 hours.</li>
    </ul>
  </Col>
);
 
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
 
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    ls("isPatient", false);
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    event.preventDefault();
    ls("isPatient", false);
    const { email, password } = this.state;
    event.preventDefault();
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((data) => {
          if (data.user.uid) {
              this.doRedirections(data.user.uid)  
          }
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  doRedirections = (uid) => {
    this.props.firebase.getPatientByUid(uid).once('value',  async snapshot => {
      const patientData = snapshot.val();
      if(patientData) {
          const key = Object.keys(patientData)[0];
          ls("isPatient", true);
          ls("name", patientData[key]["name"]);
          ls("id", key);
          ls("role", "patient");
          this.props.history.push("/patient-details/"+key);
          this.props.history.go(0);
      } else {
        const eventref = this.props.firebase.getVolunteerByUid(uid);
        const snapshot = await eventref.once('value');
        const volInfo = snapshot.val();
        if(volInfo) {
          const key = Object.keys(volInfo)[0];
          ls("name", volInfo[key]["name"]);
          ls("id", key);
          ls("role", volInfo[key]["type"]);
        }
        this.props.history.push(ROUTES.home.path);
        this.props.history.go(0);
      }
      this.setState({ ...INITIAL_STATE });
  });
  }
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, password, error } = this.state;
 
    const isInvalid = password === '' || email === '';
 
    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          className="form-control mb-3"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <input
          name="password"
          className="form-control mb-3"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        <Button disabled={isInvalid} type="submit">
          Sign In
        </Button>
        <PasswordForgetLink />
 
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
 
const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);
 
export default SignInPage;
 
export { SignInForm };