import React, { Component } from 'react';
import { REGISTER_LABELS } from '../../constants/labels'
import { Container, Row, Col } from 'react-bootstrap'
import { STATES, GENDER } from '../../constants/fields'
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';


class VolunteerDetail extends Component {
    constructor(props) {
        super(props);
        this.state ={volunteer: null};
    }

    componentDidMount = () => {
        this.props.firebase.getVolunteerDetails(this.props.volunteerId).on('value', snapshot => {
            const obj = snapshot.val();
            if (obj) {
                this.setState({"volunteer": obj });
            }
        });
    }

    render() { 
        return ( this.state.volunteer && <Container>
            <Row>
                <Col className="text-center"><h2>{this.state.volunteer.type+" Details"}</h2></Col>
            </Row>
                <Row>
                    <Col>{REGISTER_LABELS.name}</Col>
                    <Col>{this.state.volunteer.name}</Col>
                </Row>
                <Row>
                    <Col>{REGISTER_LABELS.age}</Col>
                    <Col>{this.state.volunteer.age}</Col>
                </Row>
                <Row>
                    <Col>{REGISTER_LABELS.gender}</Col>
                    <Col>{GENDER[this.state.volunteer.gender]}</Col>
                </Row>
                <Row>
                    <Col>{REGISTER_LABELS.mobile}</Col>
                    <Col>{this.state.volunteer.mobile}</Col>
                </Row>
                <Row>
                    <Col>{REGISTER_LABELS.email}</Col>
                    <Col>{this.state.volunteer.email}</Col>
                </Row>
                { this.props.type === "Doctor" &&
                    <div>
                    <Row>
                        <Col>{REGISTER_LABELS.degree}</Col>
                        <Col>{this.state.volunteer.degree}</Col>
                    </Row>
                    <Row>
                        <Col>{REGISTER_LABELS.college}</Col>
                        <Col>{this.state.volunteer.college}</Col>
                    </Row>
                    <Row>
                        <Col>{REGISTER_LABELS.regId}</Col>
                        <Col>{this.state.volunteer.regId}</Col>
                    </Row>
                    </div>
                }
                <Row>
                    <Col>{REGISTER_LABELS.specialization}</Col>
                    <Col>{this.state.volunteer.specialization}</Col>
                </Row>
                <Row>
                    <Col>{this.props.type === "Doctor"?REGISTER_LABELS.hospital:REGISTER_LABELS.institute}</Col>
                    <Col>{this.state.volunteer.institute}</Col>
                </Row>
                <Row>
                    <Col>{REGISTER_LABELS.area}</Col>
                    <Col>{this.state.volunteer.area}</Col>
                </Row>
                <Row>
                    <Col>{REGISTER_LABELS.city}</Col>
                    <Col>{this.state.volunteer.city}</Col>
                </Row>
                <Row>
                    <Col>{REGISTER_LABELS.state}</Col>
                    <Col>{STATES[this.state.volunteer.state]}</Col>
                </Row>
            </Container> );
    }
}
 
export default compose(withFirebase)(VolunteerDetail);