import app from "firebase/app";
import "firebase/auth";
import 'firebase/database';
import 'firebase/functions'
import ls from 'local-storage';
import 'firebase/storage';
//import 'firebase/analytics';
import moment from 'moment';
import dbconfig from './config.js';

class Firebase {
  constructor() {
    app.initializeApp(dbconfig);

    this.auth = app.auth();
    this.db = app.database();
    this.functions = app.functions();
    this.storage = app.storage();
  //  this.analytics = app.analytics();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => { this.auth.signOut(); };
  doSignOutRedirect = () => { this.doSignOut(); ls.clear(); window.location.href = "/" };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);
  
  /*getCustomClaims = () => { 
    if (this.auth.currentUser === undefined) return;
    else { 
      this.auth.currentUser.getIdTokenResult()
      .then((idTokenResult) => {
      // Confirm the user is an Admin.
      if (!!idTokenResult.claims.admin) {
        console.log('Show admin UI')
        //  showAdminUI();
      } else {
        console.log('Show regular user UI');
        //  showRegularUI();
      }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };*/

  /*this.auth.onAuthStateChanged(function(user) {
    if (user) {
      // User is signed in.
      getCustomClaims();
    } else {
      // No user is signed in.
    }
  });*/


  loginAnon = () => {
    this.auth.signInAnonymously().catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode, " :: ", errorMessage);
    });
  };

  // *** User API ***
 
  user = uid => this.db.ref(`users/${uid}`);
 
  users = () => this.db.ref('users');

  createPatientRecord = (patientInfo) => this.db.ref('patients').push(patientInfo);

  // uncomment if required.
  //updatePatientRecord = (patientInfo) => this.db.ref('patients/' + patientInfo.id).update({volunteer:patientInfo.volunteer, doctor:patientInfo.doctor}, err => console.log(err));

  updatePatientAttribute = (patientId, key, value) => this.db.ref('patients/' + patientId).update({[key]:value}, err => console.log(err));

  getPatients = () => this.db.ref('patients');

  getPatientCount = () => this.db.ref('metadata/patientCount');
  getVolunteerCount = () => this.db.ref('metadata/volunteerCount');

  getPatientsFirstPage = (pageSize) => this.db.ref('patients').limit(pageSize);

  getPatientsPaginated = (startId, pageSize) => this.db.ref('patients').orderByKey().startAt(startId).limit(pageSize);

  getVolunteerByEmail = (Id) => this.db.ref('volunteers').orderByChild("email").equalTo(Id);

  getPatientByEmail = (Id) => this.db.ref('patients').orderByChild("email").equalTo(Id);

  getPatientByUid = (Id) => this.db.ref('patients').orderByChild("userid").equalTo(Id);

  getPatientsByVolunteer = (volunteerId, type) => this.db.ref('patients').orderByChild(type).equalTo(volunteerId);

  getVolunteerByUid = (uid) => this.db.ref('volunteers').orderByChild('userid').equalTo(uid);

  //getPatientsPaginatedByDoctor = (doctorId, start, end) => this.db.ref('patients').orderByChild("doctor").equalTo(doctorId).startAt(start).endAt(end);

  getPatientsByState = (stateCode) => this.db.ref('patients').orderByChild("state").equalTo(stateCode);

  getRegistrationsByState = (stateCode) => this.db.ref('volunteers').orderByChild("state").equalTo(stateCode);

  //getPatientsPaginatedByState = (stateCode, start, end) => this.db.ref('patients').orderByChild("state").equalTo(stateCode).startAt(start).endAt(end);

  getPatientsByAttr = (attrName, attrValue) => this.db.ref('patients').orderByChild(attrName).equalTo(attrValue)

  //getPatientsByAttrPaginated = (attrName, attrValue, start, end) => this.db.ref('patients').orderByChild(attrName).equalTo(attrValue).startAt(start).endAt(end);

  getPatientDetails = (patientId) => this.db.ref('patients/' + patientId);

  getPatientConversation = (patientId) => this.db.ref('patients/' + patientId).orderByChild("conversation");

  updatePatientConversation = (patientId, key, value) => this.db.ref('patients/ ' + patientId + '/conversation').push(value);

  getVolunteerDetails = (volunteerId) => this.db.ref('volunteers/' + volunteerId);

  getDonors = () => this.db.ref('donors');

  createVolunteerRecord = (volunteerInfo) => this.db.ref('volunteers').push(volunteerInfo);

  updateVolunteerAttribute = (volunteerId, key, value) => this.db.ref('volunteers/' + volunteerId).update({[key]:value}, err => console.log(err));

  getVolunteers = () => this.db.ref('volunteers').orderByChild("type").equalTo('Volunteer');

  getDoctors = () => this.db.ref('volunteers').orderByChild("type").equalTo('Doctor');

  getRegistrations = () => this.db.ref('volunteers').orderByKey();

  createUserRecord = (userInfo) => this.db.ref('users').push(userInfo);

  updateUserAttribute = (userId, key, value) => this.db.ref('userId/' + userId).update({[key]:value}, err => console.log(err));

  createPlasmaDonorRecord = (donorInfo) => this.db.ref('donors').push(donorInfo);

  updateUser = (data) => {
    const f = this.functions.httpsCallable('updateUser'); 
    return f(data).then(function(returnVal){ 
      console.log('status of user creation '+ returnVal);
      if(returnVal.data) {
        return true;
      }
      return false;
    })
    .catch(error => false);;
  };

  promoteToAdmin = (uid) => {
    const promoteToAdmin = this.functions.httpsCallable('promoteToAdmin');
    return promoteToAdmin(uid);
  }

  createUser = (volunteerInfo) => {
    const createUser = this.functions.httpsCallable('createUser');
    console.log('Create user callable ', createUser);
    return createUser(volunteerInfo)
    .then(function(returnVal){ 
      console.log('status of user creation '+ returnVal);
      if(returnVal.data) {
        return true;
      }
      return false;
    })
    .catch(error => false);
  };

  createPatient = (patientInfo) => {
    const createPatient = this.functions.httpsCallable('createPatient');
    console.log('Create patient callable ', createPatient);
    return createPatient(patientInfo)
    .then(function(returnVal){ 
      console.log('status of patient creation '+ returnVal);
      if(returnVal.data) {
        return true;
      }
      return false;
    })
    .catch(error => false);
  };

  fileDownload = (uid,filename) => {
    var storageRef = this.storage.ref('reports');
    var imageRef = storageRef.child(uid + "/" + filename);
    imageRef.getMetadata().then(function(metadata) {
      console.log("Created at : ", metadata.timeCreated);
    }).catch(function(error) {
      console.log("File get failed");
    });
  }

  fileListing = (uid) => {
    var storageRef = this.storage.ref('reports/' + uid);
    return storageRef.listAll().then(function(res) {
      return res.items;
    }).catch(function(error) {  
       return false;
        console.log("Failed to fetch files due to ", error.errorMessage);
    });
    
  }

  fileUpload = (uid, file) => {
    var storageRef = this.storage.ref('reports');
    var fileRef = storageRef.child(uid + "/" + moment.utc().format("YYYY-MM-DD HH:mm:ss")+" "+file.name);
    var task = fileRef.put(file);
    return task;
  }

  checkForEmail = async (email) => {
    return this.auth.fetchSignInMethodsForEmail(email).then((data) => {
      return (data.length === 0)?true:false;
    } ).catch(() => false);
  }

  createProtocolRecord = (protocol) => this.db.ref('protocol').push(protocol);

  updateProtocol = (protocolId, value) => this.db.ref('protocol/' + protocolId).update({"protocol":value}, err => console.log(err));

  getProtocol = () => this.db.ref('protocol');

  getSessionDate = () => app.database.ServerValue.TIMESTAMP;

}

export const dbRef = Firebase.db;

export default Firebase;
