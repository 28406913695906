import { Container, Col, Form, Button, Alert } from "react-bootstrap";
import React, { Component } from 'react';
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';

class AddProtocol extends Component {
    constructor(props) {
        super(props);
        this.state = { disabled: false, id: null, showAlert: false, variant: '', message: '', protocol: '' }
        this.data = {
            protocol: ""
        }
    }

    componentDidMount = () => {
        this.props.firebase.getProtocol().once('value', snapshot => {
        const obj = snapshot.val();
        if(obj) {
            const objKeys = Object.keys(obj);
              this.setState({
                id: objKeys[0],
                protocol: obj[objKeys[0]].protocol
              });
        }
        });
    }

    setValue =  evt => {
        this.data[evt.target.name] = evt.target.value;
    }

    handleSubmit = async evt => {
        evt.preventDefault();
        if(document.getElementById("myForm").checkValidity()) {
            this.setState({disabled:true});
            if(this.state.id == null) {
                this.createProtocol();
            } else {
                this.updateProtocol();
            }
        }
    }

    updateProtocol = () => {
        this.props.firebase
        .updateProtocol(this.state.id, this.data.protocol).then(() => {
            this.setState({showAlert:true, message: "Data saved successfully!", variant: 'success'});
            setTimeout(()=> this.props.history.push("/"), 6000);
        })
        .catch(error => {
            this.setState({showAlert:true, message: "Failed to save data!", variant: 'error'});
            setTimeout(()=> this.props.history.push("/"), 6000);
        });
    }

    createProtocol = () => {
        this.props.firebase.createProtocolRecord(this.data).then(() => {
            this.setState({showAlert:true, message: "Data saved successfully!", variant: 'success'});
            setTimeout(()=> this.props.history.push("/"), 6000);
        })
        .catch(error => {
            this.setState({showAlert:true, message: "Failed to save data!", variant: 'error'});
            setTimeout(()=> this.props.history.push("/"), 6000);
        });
    }

    render() {
        return ( <Container>
            {this.state.showAlert && <Alert variant={this.state.variant}><b>{this.state.message}</b></Alert>}
            <Col xs={12} md={6}>
            <Form onSubmit={this.handleSubmit} id="myForm">
            <Form.Group className="req">
                <Form.Label>Protocol</Form.Label>
                <Form.Control as="textarea" required  name="protocol" onBlur={this.setValue} rows={10} defaultValue={this.state.protocol}/>
            </Form.Group>
            <div className="text-center mb-3">
                <Button variant="secondary" type="button" className="mr-2" onClick={(e) => this.props.history.push("/") }>
                        Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={this.state.disabled}>
                    Submit
                </Button>
            </div>
            </Form>
        </Col> </Container>);
    }
}
 
export default compose(withFirebase)(AddProtocol);