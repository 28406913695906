import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { DONOR_LABELS } from '../../constants/labels'
import {  STATES, GENDER, BLOOD_GROUPS } from '../../constants/fields'
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';
import Terms from '../Static/Terms';
import {thenMessage, catchMessage} from '../../utils'

const INITIAL_STATE = {
    "name": "",
    "age": "",
    "gender": "",
    "phone": "",
    "email": "",
    "area":"",
    "city":"",
    "state":"",
    "isPositive": false,
    "positiveDate": "",
  };

class PlasmaDonor extends Component {

    constructor(props) {
        super(props);
        this.data = { ...INITIAL_STATE };
        this.state = {disabled: false, showDate: false};
    }

    setValue =  evt => {
        evt.persist();
        this.data[evt.target.name] = evt.target.value;
        if(evt.target.name === "isPositive") {
            this.setState({showDate: (evt.target.value === "Yes")?true:false});
        }
    }

    handleChecked =  evt => {
        this.data[evt.target.name] = !this.data[evt.target.name];
    }

    createDonorRecord = () => {
        this.props.firebase
            .createPlasmaDonorRecord(this.data)
            .then(() => {
                let resp = thenMessage(true);
                this.setState(resp);
                setTimeout(()=> this.props.history.push("/"), 2000);
            })
            .catch(error => {
                let resp = catchMessage(error);
                this.setState(resp);
            });
    }

    handleSubmit = evt => {
        evt.preventDefault();
        if(document.getElementById("myForm").checkValidity()) {
            this.setState({disabled:true});
            var currentUser = this.props.firebase.auth.currentUser;
            
            if (currentUser) 
                this.createDonorRecord();
            else {
                this.props.firebase.loginAnon();
                this.props.firebase.auth.onAuthStateChanged(firebaseUser => {
                    console.log("Logged in anon as ", firebaseUser);
                    if (firebaseUser) {
                        this.createDonorRecord();
                        this.props.firebase.doSignOut();
                    }
                    else console.log("FirebaseUser not found");
                });
            }
        }
    }

    render() {
        return (<Container>
            {this.state.showAlert && <Alert variant={this.state.variant}><b>{this.state.message}</b></Alert>}
            <Row>
                <Col className="text-center"><h2>{DONOR_LABELS.formTitle}</h2></Col>
            </Row>
            <Form className="my-form" onSubmit={this.handleSubmit} id="myForm">
                <Row>
                    <Col>
                        <b>{DONOR_LABELS.plasmaDonorTitle}</b>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="req">
                            <Form.Label>{DONOR_LABELS.name}</Form.Label>
                            <Form.Control type="text" required  name="name" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{DONOR_LABELS.age}</Form.Label>
                            <Form.Control type="number" required  name="age" onChange={this.setValue} min={0} max={120}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{DONOR_LABELS.gender}</Form.Label>
                            <Form.Control as="select" required  name="gender" onChange={this.setValue}>
                            <option value="">Select a value</option>
                            {
                                Object.keys(GENDER).map((val) => {
                                    return <option value={val}>{GENDER[val]}</option>
                                }) 
                            }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{DONOR_LABELS.mobile}</Form.Label>
                            <Form.Control type="tel" required  name="phone" onChange={this.setValue} pattern="^\+[1-9]\d{1,14}$" placeholder="Append your country code +91"/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{DONOR_LABELS.email}</Form.Label>
                            <Form.Control type="email" required  name="email" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{DONOR_LABELS.area}</Form.Label>
                            <Form.Control type="text" required name="area" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{DONOR_LABELS.city}</Form.Label>
                            <Form.Control type="text" required  name="city" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{DONOR_LABELS.state}</Form.Label>
                            <Form.Control as="select" required  name="state" onChange={this.setValue}>
                            <option value="">Select a state</option>
                            {
                                Object.keys(STATES).map((state) => {
                                    return <option value={state}>{STATES[state]}</option>
                                }) 
                            }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{DONOR_LABELS.bloodGroup}</Form.Label>
                            <Form.Control as="select" required  name="bloodGroup" onChange={this.setValue}>
                            <option value="">Select a value</option>
                            {
                                BLOOD_GROUPS.map((group) => {
                                    return <option value={group}>{group}</option>
                                }) 
                            }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="req" xs={12} md={6}>
                    <Form.Group className="req">
                            <Form.Label className="bl">{DONOR_LABELS.isPositive}</Form.Label>
                            <Form.Control as="select" required  name="isPositive" onChange={this.setValue} className='si'>
                            <option value="">Select a value</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    {
                    this.state.showDate && 
                    <Col md={6}>
                        <Form.Group className="req" xs={12} md={6}>
                            <Form.Label>{DONOR_LABELS.positiveDate}</Form.Label>
                            <Form.Control type="date" required  name="positiveDate" onChange={this.setValue} min="2019-12-01" />
                        </Form.Group>
                    </Col>
                    }
                </Row>
                <Row>
                    <Col className="req">
                        <Form.Check
                                required
                                type="checkbox"
                                label={<Terms form="donor" />}
                                value="agreed"
                                name="terms"
                                onChange={this.handleChecked}
                            />
                    </Col>
                </Row>
                <div className="text-center mb-3">
                    <Button variant="secondary" type="button" className="mr-2" onClick={(e) => this.props.history.push("/") }>
                            Cancel
                    </Button>
                    <Button variant="primary" type="submit" disabled={this.state.disabled}>
                        Submit
                    </Button>
                </div>
            </Form>
        </Container>);
    }
}

export default compose(withFirebase)(PlasmaDonor);
