import React from 'react';
import { Container } from 'react-bootstrap'

const About = () => {
    return <Container>
        <h1 class="text-center mb-5">WHO WE ARE</h1>
        <p class="text-justify">We are one of you – children of this great motherland of ours.</p>
        <p class="text-justify"> We are proud Bharatiyas who believe that every one of the 1.3 billion
        of our countrymen and also those unborn millions, who will one day
        inherit this land, deserves a life of peace, prosperity and purpose.
        </p>
        <p class="text-justify">
            We haven’t forgotten that the life we enjoy today, the freedom and
            opportunities that has come our way is the result of selfless sacrifices
            of countless daughters and sons of this sacred land. And that we owe
            a debt to these great souls, the debt to pass on the spirit of freedom,
            justice and equality to our future generations.
        </p>
        <p class="text-justify">
            We feel in our bones and blood the wisdom of renunciation and
            service that has been passed on to us though our culture, our value
            systems, our epics and our timeless traditions. We are the inheritors
            of the wise sages of the ancient times, the valiant heroes of the past
            centuries, the fearless freedom fighters of the last century.
        </p>
        <p class="text-justify">
            You too, we are sure hear the faint calling of our Motherland, urging
            each one of us to rise up, come together and fight for what is right
            and what is moral.
        </p>
        <p class="text-justify">
            The stakes have never been higher, the crisis never more critical, the
            struggle never more demanding. We are those Bharatiyas who are
            ready to take the plunge, to do or die, to struggle until we have
            achieved the goal of a developed nation, Bharat!
        </p>
        <p class="text-justify">
            This is the only way we can pay our debt to our forefathers, to our
            motherland and to the gods who have blessed us with life and
            conscience.
        </p>
    </Container>;
}

export default About;