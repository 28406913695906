export const thenMessage = (res) => { 
    if(res) {
        window.scrollTo(0, 0);
        return {
            variant: "success",
            message: "Thank you for signing up. Please check your email for further instructions.",
            showAlert: true
        }
    } else {
        window.scrollTo(0, 0);
        return {
            variant: "danger",
            message: "Something went wrong, please try again later!",
            showAlert: true,
            disabled: false
        }
    }
}

export const catchMessage = (error) => {
    window.scrollTo(0, 0);
    return {
        variant: "danger",
        message: "Something went wrong, please try again later!",
        showAlert: true,
        disabled: false
    }
}
