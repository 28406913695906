import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import ls from 'local-storage';
 
const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
        super(props);
   
        this.state = {
          authUser: null,
          isAdmin: false,
        };
      }

      componentDidMount() {
          
          this.listener = this.props.firebase.auth.onAuthStateChanged(
            authUser => {
              authUser
                ? this.setState({ authUser })
                : this.setState({ authUser: null });
                if(ls.get("isAdmin") === true) {
                  this.setState({isAdmin:true}); 
                }
                if(authUser && ls.get("isAdmin") === null) {
                  ls("userEmail", authUser.email);
                  authUser.getIdTokenResult(true) 
                  .then((idTokenResult) => {
                  if (idTokenResult.claims.administrator) { 
                      this.setState({isAdmin:true});   
                      ls("isAdmin", true)
                  }
                  })
                  .catch((error) => {
                      console.log(error);
                  });
                }
              },
          );
      }

      componentWillUnmount() {
        this.listener();
      }

      setAdminUser() {

      }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} isAdmin={this.state.isAdmin} />
        </AuthUserContext.Provider>
      );
    }
  }
 
  return withFirebase(WithAuthentication);
};
 
export default withAuthentication;