import React from 'react';
import {Carousel} from 'react-bootstrap'
const Slider = () => {
    return <Carousel>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src="/assets/slide1.jpg"
        alt="Doctor"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src="/assets/slide2.jpg"
        alt="Testing"
      />
    </Carousel.Item>
  </Carousel>;
}
 
export default Slider;