import React, { Component } from 'react';
import { REGISTER_LABELS } from '../../constants/labels'
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { STATES, GENDER, PREFIXES } from '../../constants/fields'
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';
import Terms from '../Static/Terms';
import {thenMessage, catchMessage} from '../../utils'


const INITIAL_DATA = {
    "name": "",
    "age": "",
    "gender": "",
    "mobile": "",
    "email": "",
    "area": "",
    "city": "",
    "state": "",
    "degree": "",
    "specialization": "",
    "institute": "",
    "consent": false,
    "terms": false,
    "prefix": "+91"
};

class Register extends Component {
    constructor(props) {
        super(props);
        this.data = { ...INITIAL_DATA, type: this.props.type };
        this.state = {disabled: false};
    }

    handleChecked = evt => {
        this.data[evt.target.name] = !this.data[evt.target.name];
    }

    setValue =  evt => {
        this.data[evt.target.name] = evt.target.value;
    }

    createVolunteerRecord = async () => {
        let res = await this.props.firebase.createUser(this.data);
        let resp = thenMessage(res);
        this.setState(resp);
        setTimeout(()=> this.props.history.push("/"), 6000);
    }
    
    handleSubmit = async evt => {
        evt.preventDefault();
        const isUnique = await this.props.firebase.checkForEmail(this.data.email);
        if(!isUnique) {
            alert("Email already exits!")
        }
        if(document.getElementById("myForm").checkValidity() && isUnique) {
            this.data.mobile = this.data.prefix+this.data.mobile;
            this.setState({disabled:true});
            this.createVolunteerRecord();
        }     
    }

    render() { 
        return ( <Container>
            {this.state.showAlert && <Alert variant={this.state.variant}><b>{this.state.message}</b></Alert>}
            <Row>
                <Col className="text-center"><h2>{this.props.type === "Doctor"?REGISTER_LABELS.doctorTitle:REGISTER_LABELS.volunteerTitle}</h2></Col>
            </Row>
            <Form className="my-form" onSubmit={this.handleSubmit} id="myForm">
                <Form.Group className="req">
                    <Form.Label>{REGISTER_LABELS.name}</Form.Label>
                    <Form.Control type="text" required name="name" onChange={this.setValue}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{REGISTER_LABELS.age}</Form.Label>
                    <Form.Control type="number" name="age" onChange={this.setValue} min={0} max={120}/>
                </Form.Group>
                <Form.Group  className="req">
                    <Form.Label>{REGISTER_LABELS.gender}</Form.Label>
                    <Form.Control as="select" required  name="gender" onChange={this.setValue}>
                        <option value="">Select a value</option>
                        {
                            Object.keys(GENDER).map((val) => {
                                return <option value={val}>{GENDER[val]}</option>
                            }) 
                        }
                    </Form.Control>
                </Form.Group>
                <Form.Group className="req">
                    <Form.Label>{REGISTER_LABELS.mobile}</Form.Label>
                    <div class="mobile-block">
                    <Form.Control as="select" required name="prefix" onChange={this.setValue} className="prefix">
                        {
                            PREFIXES.map((val) => {
                                return <option value={val}>{val}</option>
                            }) 
                        }
                    </Form.Control>
                    <Form.Control type="tel" required name="mobile" onChange={this.setValue} pattern="^\d{1,14}$"/>
                    </div>
                </Form.Group>
                <Form.Group className="req">
                    <Form.Label>{REGISTER_LABELS.email}</Form.Label>
                    <Form.Control type="email" required name="email" onChange={this.setValue}/>
                </Form.Group>
                { this.props.type === "Doctor" &&
                    <div>
                    <Form.Group className="req">
                        <Form.Label>{REGISTER_LABELS.degree}</Form.Label>
                        <Form.Control type="text" required name="degree" onChange={this.setValue}/>
                    </Form.Group>
                    <Form.Group className="req">
                        <Form.Label>{REGISTER_LABELS.college}</Form.Label>
                        <Form.Control type="text" required name="college" onChange={this.setValue}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{REGISTER_LABELS.regId}</Form.Label>
                        <Form.Control type="text" name="regId" onChange={this.setValue}/>
                    </Form.Group>
                    </div>
                }
                <div class="req">
                <Form.Group>
                    <Form.Label>{REGISTER_LABELS.specialization}</Form.Label>
                    <Form.Control type="text" required name="specialization" onChange={this.setValue}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{this.props.type === "Doctor"?REGISTER_LABELS.hospital:REGISTER_LABELS.institute}</Form.Label>
                    <Form.Control type="text" required name="institute" onChange={this.setValue}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{REGISTER_LABELS.area}</Form.Label>
                    <Form.Control type="text" required name="area" onChange={this.setValue}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{REGISTER_LABELS.city}</Form.Label>
                    <Form.Control type="text" required name="city" onChange={this.setValue}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{REGISTER_LABELS.state}</Form.Label>
                    <Form.Control as="select" required  name="state" onChange={this.setValue}>
                        <option value="">Select a state</option>
                    {Object.keys(STATES).map((state) => {
                        return <option value={state}>{STATES[state]}</option>
                    }) 
                    }
                    </Form.Control>
                </Form.Group>
                { this.props.type !== "Doctor" &&
                <Row>
                    <Col>
                        <Form.Check
                                required
                                type="checkbox"
                                label={REGISTER_LABELS.consent}
                                value="agreed"
                                name="consent"
                                onChange={this.handleChecked}
                            />
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                        <Form.Check
                                required
                                type="checkbox"
                                label={<Terms form={this.props.type} />}
                                value="agreed"
                                name="terms"
                                onChange={this.handleChecked}
                            />
                    </Col>
                </Row>
                </div>
                <div className="text-center">
                <Button variant="secondary" type="button" className="mr-2" onClick={(e) => this.props.history.push("/") }>
                            Cancel
                    </Button>
                    <Button variant="primary" type="submit" disabled={this.state.disabled}>
                        Submit
                    </Button>
                </div>
            </Form>
            </Container> );
    }
}
 
export default compose(withFirebase)(Register);