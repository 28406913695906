import React, { Component } from 'react';
import { Container, Table, Form, Row, Col, Alert } from 'react-bootstrap'
import { ADDUSER_LABELS } from '../../constants/labels'
import {STATES} from '../../constants/fields';
import { PER_PAGE } from '../../constants/fields'
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';
import { PencilSquare, XCircle } from 'react-bootstrap-icons';
import ViewDetail from '../Static/ViewDetail'
import ReactPaginate from 'react-paginate';

const INITIAL_STATE = {
    volunteers: [],
    allVolunteers: [],
    editId: "",
    message: "",
    perPage: 5,
    noData: false
  };

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE};
    }
    
    componentDidMount() {
        this.props.firebase.getRegistrations().on('value', snapshot => this.setStateData("allVolunteers", snapshot));
    }

    setStateData = (stateVar, snapshot) => {
        this.setState({ noData: false});
        const obj = snapshot.val();
        if(obj) {
            const objList = Object.keys(obj).map(key => ({
                ...obj[key],
                uid: key,
              }));
              this.setState({
                [stateVar]: objList,
              });
            const volunteers = objList.slice(0, this.state.perPage);
            this.setState({volunteers, volunteerCount: objList.length})
        } else {
            this.setState({
                [stateVar]: [],
              });
              this.setState({volunteers: [], noData: true})
        }
    }

    setValue =  async evt => {
        evt.persist();
        const userId = evt.target.dataset.id;
        const value = evt.target.value;
        const key = evt.target.name;
        const res = await this.props.firebase.updateUser({'uid':userId, 'key':key, 'value':value})
        if(res) {
            this.setState({message:"Saved!"});
            if (key==='disabled') this.props.firebase.updateVolunteerAttribute(this.state.vId, key, value);
            evt.target.parentElement.className = "success"
            setTimeout(() => {
                evt.target.parentElement.className = null
            }, 5000)
        } else {
            this.setState({message:"Failed to save!"});
            evt.target.parentElement.className = "error"
            setTimeout(() => {
                evt.target.parentElement.className = null
            }, 5000)
        }
    }

    editRow = evt => {
        const editId = evt.target.parentNode.dataset.id;
        const vId = evt.target.parentNode.dataset.vid;
        this.setState({editId,vId});
    }

    cancel = () => this.setState({editId:""});

    stateSelected = (evt) => {
        const stateVal = evt.target.value;
        if(stateVal === "") {
            this.props.firebase.getRegistrations().once('value', snapshot => this.setStateData("allVolunteers", snapshot));
        } else {
            this.props.firebase.getRegistrationsByState(stateVal).once('value', snapshot => this.setStateData("allVolunteers", snapshot)); 
        }
    }

    setPerPage = evt => {
        this.setState({perPage:evt.target.value}, () => {
            this.pageChanged({selected:0});
        })
    }

    pageChanged = (evt) => {
        this.setState({activePage: evt.selected});
        const start = this.state.perPage * evt.selected;
        const volunteers = this.state.allVolunteers.slice(start, start+ parseInt(this.state.perPage));
        this.setState({volunteers});
    }

    render() {
        return <Container>
            <Row className="mb-3">
                <Col md={{ span: 2, offset: 8 }} xs={12} className="text-right"> 
                    Filter:
                </Col>
                    <Col xs={12} md={2}>
                    <Form.Control as="select"  name="state" onChange={this.stateSelected}>
                        <option value="">All States</option>
                        {
                            Object.keys(STATES).map((state) => {
                                return <option value={state}>{STATES[state]}</option>
                            }) 
                        }
                    </Form.Control>
                    </Col>
            </Row>
            <Table striped bordered hover className="tbl">
                <thead>
                    <tr>
                    {
                        ADDUSER_LABELS.map((label) => {
                            return <th>{label}</th>
                        })
                    }
                    </tr>
                </thead>
                <tbody>
                    {
                    this.state.volunteers.map((volunteer) => {
                    return <tr className="ss">
                    <td><ViewDetail volunteerId={volunteer['uid']} name={volunteer['name']}  type="volunteer"/></td>
                    <td>{volunteer['type']}</td>
                    <td>{volunteer['email']}</td>
                    <td>{STATES[volunteer['state']]}</td>
                    {
                    (this.state.editId === volunteer['userid']) ?
                        <td><Form.Control required  name="password" type="text" onChange={this.setValue} data-id={volunteer.userid} /><div className="inline-msg">{this.state.message}</div></td>
                        :<td>*****</td>
                    }
                    {
                    (this.state.editId === volunteer['userid'])?
                        <td><Form.Control as="select" required  name="disabled" onChange={this.setValue} data-id={volunteer.userid}>
                            <option value="">Select a value</option>
                            <option value={false} selected={(volunteer['disabled']==="false")?'selected':''}>Enable</option>
                            <option value={true} selected={(volunteer['disabled']==="true")?'selected':''}>Disable</option>
                        </Form.Control><div className="inline-msg">{this.state.message}</div></td>:
                        <td>{(volunteer['disabled'] === "false")?"Enabled":"Disabled"}</td>
                    }
                    <td className="text-center">
                    {
                    (this.state.editId !== volunteer['userid'])?<a data-id={volunteer['userid']} data-vid={volunteer['uid']} onClick={this.editRow} href="#edit"><PencilSquare width="24" height="24" className="ai"/></a>:
                    <a data-id={volunteer['userid']} onClick={this.cancel} href="#cancel"><XCircle width="24" height="24" className="text-danger"/></a>
                    }
                    </td>
                    </tr>
                    })
                }
                </tbody>
            </Table>
            { 
                this.state.noData &&  <Alert variant="warning">
                There is no data with this search criteria!
                </Alert>
            }
            <div class="text-right">
            <div  style={{display:"inline-block", "vertical-align": "top"}} className="mr-3">per page: <Form.Control as="select" onChange={this.setPerPage} style={{display:"inline-block",width:"60px"}}>
                {
                    PER_PAGE.map((val) => {
                    return <option value={val} selected={val === this.state.perPage}>{val}</option>
                    }) 
                }
            </Form.Control></div>
            <ReactPaginate containerClassName={'pagination'}
                subContainerClassName={'pages pagination'} 
                activeClassName={'active'}
                onPageChange={this.pageChanged}
                itemsCountPerPage={this.state.perPage}
                totalItemsCount={this.state.volunteerCount}
                pageRangeDisplayed={3}
                activePage={this.state.activePage}
                pageCount={Math.ceil(this.state.volunteerCount/this.state.perPage)}
                />
                </div>
        </Container>
    }
}

export default compose(withFirebase)(AddUser);
