export const SYMPTOMS = [
    "Cough",
    "Fever",
    "Difficulty in Breathing",
    "Chest Pain",
    "Weakness",
    "Sore Throat",
    "Diarrhoea",
    "Headache",
    "Loss of smell",
    "Loss of taste"
]

export const STATES = {
    "AN":"Andaman and Nicobar Islands",
    "AP":"Andhra Pradesh",
    "AR":"Arunachal Pradesh",
    "AS":"Assam",
    "BR":"Bihar",
    "CG":"Chandigarh",
    "CH":"Chhattisgarh",
    "DN":"Dadra and Nagar Haveli",
    "DD":"Daman and Diu",
    "DL":"Delhi",
    "GA":"Goa",
    "GJ":"Gujarat",
    "HR":"Haryana",
    "HP":"Himachal Pradesh",
    "JK":"Jammu and Kashmir",
    "JH":"Jharkhand",
    "KA":"Karnataka",
    "KL":"Kerala",
    "LA":"Ladakh",
    "LD":"Lakshadweep",
    "MP":"Madhya Pradesh",
    "MH":"Maharashtra",
    "MN":"Manipur",
    "ML":"Meghalaya",
    "MZ":"Mizoram",
    "NL":"Nagaland",
    "OR":"Odisha",
    "PY":"Puducherry",
    "PB":"Punjab",
    "RJ":"Rajasthan",
    "SK":"Sikkim",
    "TN":"Tamil Nadu",
    "TS":"Telangana",
    "TR":"Tripura",
    "UP":"Uttar Pradesh",
    "UK":"Uttarakhand",
    "WB":"West Bengal",
    "OT":"Other"
}

export const GENDER = {
    "M":"Male",
    "F":"Female",
    "O":"Other"
}

export const PROGRESS = {
    1: "Yet to Assign",
    2: "Volunteer Assigned",
    3: "Patient Contacted by Volunteer",
    4: "Doctor Informed",
    5: "Treatment in Progress"
}

export const OVERALL_STATUS = {
    1: "Yet to Assign",
    2: "Assigned",
    3: "Out Patient",
    4: "In Patient",
    5: "Discharged",
    6: "Deceased"
}

export const USER_STATUS ={
    "active": "Active",
    "inactive": "Inactive"
}

export const BLOOD_GROUPS = [
    "A+",
    "A-",
    "B+",
    "B-",
    "AB+",
    "AB-",
    "O+",
    "O-",
    "Hh"
]

export const FILTER_OPTIONS = {
    "all": "All",
    "state": "By State",
    "doctor": "By Doctor"
}

export const PER_PAGE = [5, 10, 25, 50];

export const PREFIXES = ["+91", "+1", "+44"];