import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { PAITENT_LABELS } from '../../constants/labels'
import { STATES, GENDER } from '../../constants/fields'
import { withFirebase } from '../Firebase'
import { compose } from 'recompose';
import Conversation from './Conversation';
import moment from 'moment'

class PatientDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: null,
            showConversation: false
        }
    }

    componentDidMount = () => {
        const calledAs = (this.props.patientId?"modal":"page");
        if(calledAs === "page") {
            this.setState({
                showConversation: true
            })
        }
        const patientId = ((calledAs === "modal")?this.props.patientId:this.props.match.params.patientId);
        this.props.firebase.getPatientDetails(patientId).on('value', snapshot => {
            const obj = snapshot.val();
            if (obj) {
                obj['uid'] = patientId;
                this.setState({"patient": obj });
            }
        });
    }

    render() {
        return (this.state.patient && <Container className="details">
            <h2 className="text-center"> Patient Details</h2>
            <hr />
            <Row>
                <Col >
                    <label>{PAITENT_LABELS.name}</label>
                    <spam class="val">{this.state.patient.name}</spam>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.age}</label>
                    <spam class="val">{this.state.patient.age}</spam>
                </Col>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.gender}</label>
                    <spam class="val">
                    {GENDER[this.state.patient.gender]}
                    </spam>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.mobile}</label>
                    <spam class="val">{this.state.patient.phone}</spam>
                </Col>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.email}</label>
                    <spam class="val">{this.state.patient.email}</spam>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.area}</label>
                    <spam class="val">{this.state.patient.area}</spam>
                </Col>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.city}</label>
                    <spam class="val">{this.state.patient.city}</spam>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.state}</label>
                    <spam class="val">{STATES[this.state.patient.state]}</spam>
                </Col>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.date}</label>
                    <spam class="val">{this.state.patient.createdAt?moment.unix(this.state.patient.createdAt/1000).utcOffset("+05:30").format("YYYY-MM-DD HH:mm"):""}</spam>
                </Col>
            </Row>
            <Row>
                <Col>
                    <b>{PAITENT_LABELS.symptomsTitle}</b>
                </Col>
            </Row>
            <Row>
                {this.state.patient.symptoms && this.state.patient.symptoms.map((val) => {
                    return <Col lg={3} md={3}>
                        {val}
                    </Col>
                })
                }
            </Row>
            <p></p>
            <Row>
                <Col>
                    <div><b>{PAITENT_LABELS.allergies}</b></div>
                    <spam class="val">{this.state.patient.allergies}</spam>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.temperature}</label>
                    <spam class="val">{this.state.patient.temperature}</spam>
                </Col>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.oxygenSaturation}</label>
                    <spam class="val">{this.state.patient.oxygenSaturation}</spam>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div><b>{PAITENT_LABELS.briefSym}</b></div>
                    <spam class="val">{this.state.patient.symptomsSummary}</spam>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div><b>{PAITENT_LABELS.briefProb}</b></div>
                    <spam class="val">{this.state.patient.medicalHistory}</spam>
                </Col>
            </Row>
            {/*<Row>
                <Col>
                    <div>{PAITENT_LABELS.Notes}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div><b>{PAITENT_LABELS.briefProb}</b></div>
                    <spam class="val">{this.state.patient.medicalHistory}</spam>
                </Col>
            </Row>*/}
            <Row>
                <Col>
                    <div><b>{PAITENT_LABELS.currentMedication}</b></div>
                    <spam class="val">{this.state.patient.currentMedication}</spam>
                </Col>
            </Row>
            <p></p>
            <Row>
                <Col>
                    <b>{PAITENT_LABELS.emergencyTitle}</b>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.contName}</label>
                    <spam class="val">{this.state.patient.contact.name}</spam>

                </Col>
                <Col xs={12} md={6}>
                    <label> {PAITENT_LABELS.relation}</label>
                    <spam class="val">{this.state.patient.contact.relation}</spam>

                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.contMobile}</label>
                    <spam class="val">{this.state.patient.contact.phone}</spam>
                </Col>
                <Col xs={12} md={6}>
                    <label>{PAITENT_LABELS.contEmail}</label>
                    <spam class="val">{this.state.patient.contact.email}</spam>
                </Col>
            </Row>
            <br/><br/>
            {this.state.showConversation && <Conversation {...this.props} patient={this.state.patient} /> }
        </Container>);
    }
}
export default compose(withFirebase)(PatientDetail);
