import React from 'react';
import { Modal } from 'react-bootstrap'
import { TERMS_AND_CONDITIONS } from '../../constants/labels';
const TermsModal = (props) => {
    return <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
      Terms and Conditions
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className="text-justify">
        {TERMS_AND_CONDITIONS[props.form]}
      </p>
    </Modal.Body>
  </Modal>
}
 
const Terms = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
      return (<>
      I agree to the <a href="#terms" onClick={() => setModalShow(true)}> Terms and Conditions</a>
      <TermsModal
        show={modalShow}
        form={props.form}
        onHide={() => setModalShow(false)}
      />
      </>)
}
export default Terms;