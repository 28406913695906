import React from 'react';
import { Container } from 'react-bootstrap';

const HomePageText = () => {
    return <Container>
        <p class="text-justify">
        Shwas (Upiri), <b>a virtual COVID treatment program</b> was started with a goal to provide <b>free advice or care</b> to patients due to limited healthcare resources and shortage of beds in hospitals across Bharat. Doctors across the country have joined hands to provide treatment with the human touch. The aim of Shwas/Upiri is to coordinate care for COVID positive patients or those with COVID symptoms. Doctors will give advice or formulate a treatment regimen and essential equipment for patients, which are cheap and conforming to the standard of care.
        </p>
        <p className="text-justify">
        Shwas (Upiri) program will reach out to people across all sections of the society in the entire country. Due to shortage of doctors and volunteers, it is not always possible to give advise or provide care to every patient who has requested help. In the meantime, patients are advised to seek help from their local doctors and the nearest hospital without waiting. In cases of emergency, the patients are advised to go to the nearest hospital without any delay.
        </p>
    </Container>;
}

export default HomePageText;