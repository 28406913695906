import { Container, Col } from "react-bootstrap";
import React, { Component } from 'react';
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';
class Protocol extends Component {
    constructor(props) {
        super(props);
        this.state = { protocol: '' }
    }

    componentDidMount = () => {
        this.props.firebase.getProtocol().once('value', snapshot => {
        const obj = snapshot.val();
        if(obj) {
            const objKeys = Object.keys(obj);
              this.setState({
                id: objKeys[0],
                protocol: obj[objKeys[0]].protocol
              });
        }
        });
    }

    render() {
        return ( <Container>
            <h1 class="text-center mb-5">PROTOCOL</h1>
            <Col xs={12}>
            <div className="text-justify" dangerouslySetInnerHTML={{__html: this.state.protocol}}></div>
        </Col> </Container>);
    }
}
 
export default compose(withFirebase)(Protocol);