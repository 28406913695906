const dbconfig = {
        apiKey: "AIzaSyCVw0lt3CeyAxGA_wtBE7Qcz6oTT6IDjeg",
        authDomain: "hellodoctor-fb657.firebaseapp.com",
        databaseURL:"https://hellodoctor-fb657.firebaseio.com",
        projectId: "hellodoctor-fb657",
        storageBucket: "hellodoctor-fb657.appspot.com",
        messagingSenderId: "37607384736",
        appId: "1:37607384736:web:2d3580fc2c3407795acbc5",
        measurementId: "G-QVJMLB4XWB"
};

// const dbconfig = {
//         apiKey: "AIzaSyBH-mo-TlA2xgNaVerFyY_6u6_8psGMMBk",
//         authDomain: "hellodoctor-test-b2439.firebaseapp.com",
//         databaseURL: "https://hellodoctor-test-b2439.firebaseio.com",
//         projectId: "hellodoctor-test-b2439",
//         storageBucket: "hellodoctor-test-b2439.appspot.com",
//         messagingSenderId: "973068839909",
//         appId: "1:973068839909:web:6bafdcd35618343efa5d0c",
//         measurementId: "G-F8W56ZQC45"
// };

export default dbconfig;