import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import {PATIENTFORM_ROUTE, DCTORFORM_ROUTE, VOLUNTEERFORM_ROUTE, PLASMA_ROUTE} from '../../constants/routes'
import { Link } from 'react-router-dom';
import HomePageText from './HomePageText';
import Slider from './Slider';
import ROUTES from '../../constants/routes';

const Home = () => {
  return <div>
    <Container>
    <Row>
        <Col xs={12} md={7} ><HomePageText/></Col>
        <Col xs={12} md={5} ><Slider/></Col>
    </Row>
    </Container>
    <Container>
      <Row>
        <Col xs={12} md >
          <Card className="m-2 patient-card">
            <Card.Body>
              <Card.Title>For Patients</Card.Title>
              <Card.Text className="min-card"> 
                If you are feeling/suffering from the symptoms of COVID-19, please share your details. We will try our best to help you.
              </Card.Text>
              
              <Link to={ROUTES.login.path}><Button variant="success">Existing Patients - Login</Button></Link>
              <Link to={PATIENTFORM_ROUTE}><Button className="float-right" variant="primary">Proceed to Patient Details</Button></Link>
            </Card.Body>
        </Card>
        </Col>
        <Col xs={12} md >
          <Card className="m-2">
            <Card.Body>
              <Card.Title>For Volunteers</Card.Title>
              <Card.Text className="min-card">
                If you are medical staff (nurse, pharmacist) or medical student, come join us in the battle against COVID-19.
              </Card.Text>
              <Link to={VOLUNTEERFORM_ROUTE}><Button className="float-right"  variant="primary">Be a Volunteer - Join Us</Button></Link>
            </Card.Body>
        </Card>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md >
          <Card className="m-2">
            <Card.Body>
              <Card.Title>For Doctors</Card.Title>
              <Card.Text className="min-card">
                Only doctors know that the true wealth of India lies in the health of the people! Sign up if you are already treating COVID-19.
              </Card.Text>
              <Link to={DCTORFORM_ROUTE}><Button className="float-right"  variant="primary">Healing Hands - Join Us</Button></Link>
            </Card.Body>
        </Card>
        </Col>
        <Col xs={12} md>
          <Card className="m-2">
            <Card.Body>
              <Card.Title>For Plasma Donors</Card.Title>
              <Card.Text  className="min-card">
              Save lives!! People who have fully recovered from COVID-19 for at least two weeks are encouraged to donate plasma. Individuals must have had a prior diagnosis of COVID-19 documented by a laboratory test and meet other donor criteria. 
              </Card.Text>
              <Link to={PLASMA_ROUTE}><Button className="float-right" variant="success">Become a Corona Warrior!</Button></Link>
            </Card.Body>
        </Card>
        </Col>
      </Row>
    </Container>
    </div>;
}
 
export default Home;