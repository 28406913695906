 const ROUTES = {
    mission : {label: "Mission", path: "/mission"},
    about : {label: "Who We Are", path: "/about-us"},
    covid : {label: "COVID", path: "/covid"},
    gallery : {label: "Gallery", path: "/gallery/gallery"},
    login : {label: "Login", path: "/signin"},
    contact : {label: "Contact Us", path: "/contact-us"},
    home: {label: "Home", path: "/"},
    passwordRest: {label: "Reset Password", path: "/pw-forget"},
    admin: {label: "Admin", path: "/admin"},
    account: {label: "Account", path: "/account"},
    signup: {label: "Signup", path: "/signup"},
    addProtocol: {label: "Add Protocol", path: "/Add Protocol"},
    protocol: {label: "Protocol", path: "/protocol"},
};
export const PATIENTFORM_ROUTE = "/patient"
export const DCTORFORM_ROUTE = "/doctor"
export const VOLUNTEERFORM_ROUTE = "/volunteer"
export const ASSIGNMENT_ROUTE = "/assign"
export const ADDUSER_ROUTE = "/add-user"
export const PLASMA_ROUTE = "/plasma-donor"
export const DONORS_ROUTE = "/donors"
export const PATIENT_DETAILS_ROUTE = "/patient-details/:patientId"
export const PASSWORD_FORGET = '/pw-forget';
export const PROTOCOL_ROUTE = '/protocol';
export default ROUTES;