import React, { Component } from 'react';
import '../../chat.css';
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';
import moment from 'moment'
import ls from 'local-storage'
import { Container, Row, Col, ProgressBar, FormCheck, Form } from 'react-bootstrap';

class Conversation extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            conversations:{},
            comment:"",
            fileNames:[],
            fileUrls: {},
            uploading: false,
            progress:0,
            disabled:false,
            choosenFile:"",
            isInternal: false
        }
        this.content = '';
    }

    componentDidMount = () => {
        if(this.props.patient && this.props.patient.conversation) {
            this.setState({conversations: this.props.patient.conversation});
        }
        this.getFiles();
    }

    getFiles = async() => {
        let res = await this.props.firebase.fileListing(this.props.patient.uid);
        if(res !== false) {
            let fileNames = [];
            let fileUrls = [];
            res.forEach(async (itemRef, index) => {
                console.log("Item list: ", itemRef.fullPath);
                const url = await itemRef.getDownloadURL();
                fileUrls[itemRef.name] =  url;
                fileNames.push(itemRef.name);
                if(res.length -1  === index) {
                    setTimeout( () => {fileNames.sort();
                    fileNames.reverse();
                    this.setState({fileUrls, fileNames});
                    // var objDiv = document.getElementById("fileBox");
                    // objDiv.scrollTop = objDiv.scrollHeight;
                    }, 500);
                }
            });
            
        }
    }

    setComment = (evt) => {
        evt.persist();
        this.setState({
            comment:  evt.target.value
        })
    }

    handleFileChoosen = (evt) => {
        const file = evt.target.files[0];
        this.content = file;
        this.setState({choosenFile: file.name})
    }

    uploadFile = () => {
        if(document.getElementById("fileForm").checkValidity()) { 
         this.setState({disabled:true});
         const task = this.props.firebase.fileUpload(this.props.patient.uid, this.content);
         this.setState({uploading: true, disabled: true});
         task.on('state_changed',  (snapshot) => {
            var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            console.log(progress, "% uploaded");
            this.setState({progress});
          }, (err) => {
            console.log("Upload failure");
          }, (data) => {
              this.getFiles();
              document.getElementById("file-upload").value = "";
              this.setState({disabled:false, uploading: false, choosenFile: ""});
          });
        }
    }

    saveMessage = () => {
        const value = {
            by : ls("name"),
            byId: ls("id"),
            comment: this.state.comment,
            role: ls("role"),
            time: moment().unix(),
            isInternal: this.state.isInternal
        }
        if(document.getElementById("messageForm").checkValidity()) { 
            this.setState({disabled:true});
            var newPostKey = this.props.firebase.db.ref('patients/'+ this.props.patient.uid ).child("conversation").push().key;
            var updates = {};
            updates['/conversation/' + newPostKey] = value;
            this.props.firebase.db.ref('/patients/' + this.props.patient.uid).update(updates).then(() => {
                this.setState({
                    comment:  "",
                    disabled: false
                });
                this.props.firebase.getPatientConversation(this.props.patient.uid).once('value', snapshot => {
                    const data = snapshot.val();
                    if(data) {
                        this.setState({conversations: data.conversation}, () => {
                            var objDiv = document.getElementById("messageBox");
                            objDiv.scrollTop = objDiv.scrollHeight;
                        });
                    }
                });
            })
        } 
    }

    handleClick = () => {
        this.setState({isInternal: !this.state.isInternal});
    }
    render() {
        return <Container> 
            <Row>
            <Col md={8} xs={12}>
            <section class="msger">
            <header class="msger-header">
                <div class="msger-header-title">
                    Patient Interaction History
                </div>
            </header>

            <main class="msger-chat" id="messageBox">
                {
                    Object.keys(this.state.conversations).map((key) => {
                        let message = this.state.conversations[key];
                       return  (!message.isInternal || (message.isInternal && ls('role') !== 'patient')) && <div class={"msg "+((message.byId === ls("id"))?"right-msg":"left-msg")}>
                    <div class="msg-img"><img src={message.role === 'patient'?"/assets/patient-icon.png":"/assets/doctor-icon.png"} alt="user"/></div>
                    <div className={(message.isInternal)?"msg-bubble internal":"msg-bubble"}>
                        <div class="msg-info">
                    <div class="msg-info-name">{message.by}</div>
                    <div class="msg-info-time">{((moment.unix() - 86400) < message.time)?moment.unix(message.time).utc().fromNow():moment.unix(message.time).utc().format("YYYY-MM-DD HH:mm")}</div>
                        </div>
                    <pre class="msg-text">
                           {message.comment} 
                    </pre>
                    </div>
                </div>
                    })
                }
                {
                    (Object.keys(this.state.conversations).length === 0) &&
                    <div>
                        <p></p>
                        <p className="text-center">No messages yet!</p>
                        <p></p>
                    </div>
                }
            </main>
            { ls('role') !== "Volunteer" && 
            <form class="msger-inputarea" id="messageForm">
                <Row>
                <Col md={10} xs={12}>
                    <textarea rows="3" class="msger-input" placeholder="Enter your message..." required  pattern="^(?!\s*$).+" onChange={this.setComment} value={this.state.comment}/>
                </Col>
                <Col  md={2} xs={12} className="stack">
                {
                (ls('role') !== "patient") && 
                <FormCheck>
                <Form.Check 
                    type="switch"
                    id="custom-switch"
                    label=" "
                    inline="false"
                    onClick={this.handleClick}
                    />
                <FormCheck.Label>Internal?</FormCheck.Label>
                </FormCheck>
                }
                <button type="button" class="msger-send-btn" onClick={this.saveMessage} disabled={this.state.disabled}>Send</button>
                
                </Col>
                </Row>
            </form>
            }
        </section></Col>
        <Col md={4} xs={12}>
            <section class="msger">
                <header class="msger-header">
                    <div class="msger-header-title">
                         Patient Reports
                    </div>
                </header>

            <main class="msger-chat" id="fileBox">
                {
                this.state.fileNames.map((fileName) => {
                    return <div class="msg-bubble mb-3">
                        <div class="msg-text">
                            <a href={this.state.fileUrls[fileName]} target="blank">{fileName}</a>
                        </div>
                    </div>
                    })
                }
                {
                    (this.state.fileNames.length === 0) &&
                    <div>
                        <p></p>
                        <p className="text-center">No reports yet!</p>
                        <p></p>
                    </div>
                }
            </main>
            { ls('role') !== "Volunteer" && 
            <form  class="msger-inputarea fileAct" id="fileForm">
            <label for="file-upload" class="msger-upload-btn">
                Choose a file
            </label>
            <input type="file" class="msger-upload-btn" onChange={this.handleFileChoosen}  required id="file-upload" hidden accept="image/*,.pdf"/>
            <button type="button" class="msger-send-btn" onClick={this.uploadFile}  disabled={this.state.disabled}>Upload</button>
            </form>
            }
             { (this.state.choosenFile) && <div class="msger-inputarea"><b>{this.state.choosenFile}</b></div> }
             { this.state.uploading && <ProgressBar striped variant="warning" now={this.state.progress} animated className="m2"/> }
            </section>
        </Col>
        </Row>
        </Container>
    }
}

export default compose(withFirebase)(Conversation);