import React, { Component } from 'react';
import { Container, Table } from 'react-bootstrap'
import { DONOR_TABLE_LABELS } from '../../constants/labels'
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';
import { GENDER, STATES } from '../../constants/fields';

const INITIAL_STATE = {
    donors: [],
  };

class Donor extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE};
    }

    componentWillMount = () => {
        this.props.firebase.getDonors().on('value', snapshot => this.setStateData("donors", snapshot));
    }

    setStateData = (stateVar, snapshot) => {
        const obj = snapshot.val();
        if(obj) {
            const objList = Object.keys(obj).map(key => ({
                ...obj[key],
                uid: key,
              }));
              this.setState({
                [stateVar]: objList,
              });
        }
    }

    render() {
        return (<Container>
            <Table striped bordered hover className="tbl">
                <thead>
                    <tr>
                    {
                        Object.keys(DONOR_TABLE_LABELS).map((key) => {
                            return <th>{DONOR_TABLE_LABELS[key]}</th>
                        })
                    }
                    </tr>
                </thead>
                <tbody>
                    {
                    this.state.donors.map((donor) => {
                    return <tr>
                        {
                            Object.keys(DONOR_TABLE_LABELS).map((key) => {
                                let val = donor[key];
                                if(key === "gender"){
                                    val = GENDER[val];
                                } else if(key === "state") {
                                    val = STATES[val];
                                }
                            return <td>{val}</td>
                            })
                        }
                    </tr>
                    })
                }
                </tbody>
            </Table>
        </Container>);
    }
}

export default compose(withFirebase)(Donor);
