import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
 
import Navigation from '../Navigation';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import About from '../Static/About';
import Covid from '../Static/Covid';
import Gallery from '../Static/Gallery';
import Home from '../Static/Home';
import Mission from '../Static/Mission';
import Patient from '../Patient/Patient';
import Assignment from '../Patient/Assignment';
import Register from '../Register/Register';
import AddUser from '../Register/AddUser';
import PlasmaDonor from '../Register/PlasmaDonor';
import AddProtocol from '../Admin/AddProtocol';
import Protocol from '../Static/Protocol';
import Donor from '../Register/Donor';
import {SocialIcon} from 'react-social-icons'

 
import ROUTES, { DONORS_ROUTE ,PATIENTFORM_ROUTE, DCTORFORM_ROUTE, VOLUNTEERFORM_ROUTE, ASSIGNMENT_ROUTE, ADDUSER_ROUTE, PLASMA_ROUTE, PATIENT_DETAILS_ROUTE, PROTOCOL_ROUTE} from '../../constants/routes';

import { withAuthentication } from '../Session';
import PatientDetail from '../Patient/PatientDetail';
import ls from 'local-storage';

 
const App = (props) => (
  <Router>
    <div class='page-content'>
      <Navigation isAdmin={props.isAdmin}/>
      <Route exact path={ROUTES.home.path} component={Home} />
      <Route path={ROUTES.signup.path} component={SignUpPage} />
      <Route path={ROUTES.login.path} component={SignInPage} />
      <Route path={ROUTES.passwordRest.path} component={PasswordForgetPage}/>
      <Route path={ROUTES.account.path} component={AccountPage} />
      <Route path={ROUTES.admin.path} component={AdminPage} />
      <Route path={ROUTES.mission.path} component={Mission} />
      <Route path={ROUTES.about.path} component={About} />
      <Route path={ROUTES.gallery.path} component={Gallery} />
      <Route path={ROUTES.covid.path} component={Covid} />
      <Route path={ROUTES.addProtocol.path} component={AddProtocol} />
      <Route path={PROTOCOL_ROUTE} component={Protocol} />
      <Route path={PATIENTFORM_ROUTE} component={Patient} />
      <Route path={DCTORFORM_ROUTE} component={props => <Register {...props} type="Doctor" />} />
      <Route path={VOLUNTEERFORM_ROUTE} component={props => <Register {...props} type="Volunteer" />} />
      { !ls('isPatient') && <Route path={ASSIGNMENT_ROUTE} component={Assignment} /> }
      { props.isAdmin && <Route path={ADDUSER_ROUTE} component={AddUser}/> }
      <Route path={PLASMA_ROUTE} component={PlasmaDonor} />
      { !ls('isPatient') && <Route path={DONORS_ROUTE} component={Donor} /> }
      <Route path={PATIENT_DETAILS_ROUTE} component={PatientDetail} />
    </div>
    <footer class="footer">
      <div class="container">
        <div class="float-right">
          <SocialIcon url="https://twitter.com/ShwasUpiri" target="_blank" fgColor="#fff"/>
          <SocialIcon url="https://www.facebook.com/Shwas-Upiri-109119584266258/" target="_blank" fgColor="#fff"/>
        </div>
        <div><SocialIcon url="mailto:shwas.upiri@gmail.com" fgColor="#fff"/> shwas.upiri@gmail.com</div>
      </div>
    </footer>
  </Router>
);
 
export default withAuthentication(App);