import React, { Component } from 'react';
import { Container, Table, Form, Col, Row, Alert } from 'react-bootstrap'
import { ASSIGNMENT_LABELS } from '../../constants/labels'
import { PROGRESS, OVERALL_STATUS, USER_STATUS, FILTER_OPTIONS, PER_PAGE } from '../../constants/fields'
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';
import { PencilSquare, XCircle } from 'react-bootstrap-icons';
import {STATES} from '../../constants/fields';
import ReactPaginate from 'react-paginate'
// import ViewDetail from '../Static/ViewDetail';
import { Link } from 'react-router-dom';

import ls from 'local-storage';

const INITIAL_STATE = {
    patients: [],
    doctors: [],
    volunteers: [],
    allPatients:[],
    editId:"",
    showStates: false,
    activePage: 0,
    filter: "all",
    selectedState: "",
    message:"",
    patientCount: 0,
    perPage: 10,
    noData: false
  };

class Assignment extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE};
    }

    componentDidMount = () => {
        this.props.firebase.getPatients().once('value', snapshot => this.setStateData("allPatients", snapshot));
        this.props.firebase.getVolunteers().once('value', snapshot => this.setStateData("volunteers", snapshot));
        this.props.firebase.getDoctors().once('value', snapshot => this.setStateData("doctors", snapshot));
        if(ls("volunteerId") !== null) {
            const email = ls("userEmail");
            this.props.firebase.getVolunteerByEmail(email).once('value', snapshot => {
                const data = snapshot.val();
                if(data) {
                    const key = Object.keys(data)[0];
                    ls("volunteerId", key);
                    ls("type", data[key]["type"].toLowerCase());
                    this.setState({volunteerId: Object.keys(data)[0]});
                }
            });
        }
    }

    setStateData = (stateVar, snapshot) => {
        this.setState({ noData: false});
        const obj = snapshot.val();
        if(obj) {
            const objList = Object.keys(obj).map(key => ({
                ...obj[key],
                uid: key,
              }));
              this.setState({
                [stateVar]: objList,
              });
              if(stateVar === "allPatients") {
                const patients = objList.slice(0, this.state.perPage);
                this.setState({patients, patientCount: objList.length})
              }
        } else {
            this.setState({
                [stateVar]: [],
              });
              if(stateVar === "allPatients") {
                this.setState({patients: [], noData: true})
                
              }
        }
    }

    pageChanged = (evt) => {
        this.setState({activePage: evt.selected});
        const start = this.state.perPage * evt.selected;
        const patients = this.state.allPatients.slice(start, start+ parseInt(this.state.perPage));
        this.setState({patients});
    }

    filterChange = (evt) => {
        const filter = evt.target.value;
        this.setState({filter});
        let showStates = false;
        let showDoctor = false;
        if(filter === "state") {
            showStates = true;
            this.props.firebase.getPatients().once('value', snapshot => this.setStateData("allPatients", snapshot));
        } else if(filter === "all") {
            this.props.firebase.getPatients().once('value', snapshot => this.setStateData("allPatients", snapshot));
        } else if (filter === "doctor") {
            showDoctor = true;
            // this.props.firebase.getPatientsByVolunteer(ls("volunteerId"), ls("type")).once('value', snapshot => this.setStateData("allPatients", snapshot));
        }
        this.setState({showStates, showDoctor});
    } 

    stateSelected = (evt) => {
        const stateVal = evt.target.value;
        if(stateVal === "") {
            this.setState({showStates: false});
            this.setState({filter: "all"});
            this.props.firebase.getPatients().once('value', snapshot => this.setStateData("allPatients", snapshot));
        } else {
            this.props.firebase.getPatientsByState(stateVal).once('value', snapshot => this.setStateData("allPatients", snapshot)); 
        }
    }

    doctorSelected = (evt) => {
        const docVal = evt.target.value;
        if(docVal !== "") {
            this.props.firebase.getPatientsByVolunteer(docVal, "doctor").once('value', snapshot => this.setStateData("allPatients", snapshot))
        }
    }

    setValue =  evt => {
        evt.persist();
        const patientId = evt.target.dataset.id;
        const value = evt.target.value;
        const key = evt.target.name;
        this.props.firebase
        .updatePatientAttribute(patientId, key, value).then(() => {
            this.setState({message:"Saved!"});
            evt.target.parentElement.className = "success"
            setTimeout(() => {
                evt.target.parentElement.className = null
            }, 2000)
        })
        .catch(error => {
            this.setState({message:"Failed to save!"});
            evt.target.parentElement.className = "error"
            setTimeout(() => {
                evt.target.parentElement.className = null
            }, 2000)
            console.log(error);
        });
    }

    editRow = evt => {
        const editId = evt.target.parentNode.dataset.id;
        this.setState({editId});
    }

    setPerPage = evt => {
        this.setState({perPage:evt.target.value}, () => {
            this.pageChanged({selected:0});
        })
    }

    cancel = () => this.setState({editId:""});

    render() {
        return (<Container>
            <Row className="mb-3">
                <Col md={{ span: 2, offset: 6 }} xs={12} className="text-right"> 
                    Filter:
                </Col>
                <Col xs={12}  md={2}>
                    <Form.Control as="select" name="filter" onChange={this.filterChange}>
                        {
                            Object.keys(FILTER_OPTIONS).map((filter) => {
                                return <option value={filter} selected={this.state.filter===filter}>{FILTER_OPTIONS[filter]}</option>
                            }) 
                        }
                    </Form.Control>
                    </Col>
                    <Col xs={12} md={2}>
                    { this.state.showStates &&
                    <Form.Control as="select"  name="state" onChange={this.stateSelected}>
                        <option value="">All States</option>
                        {
                            Object.keys(STATES).map((state) => {
                                return <option value={state}>{STATES[state]}</option>
                            }) 
                        }
                    </Form.Control>
                    }
                    { this.state.showDoctor &&
                    <Form.Control as="select"  name="state" onChange={this.doctorSelected}>
                        <option value="">Select a Doctor</option>
                            {
                                this.state.doctors.map((val) => {
                                    return <option value={val['uid']}>{val['name'] +" - "+ val['state']}</option>
                                }) 
                            }
                    </Form.Control>
                    }
                    </Col>
            </Row>
            <Table striped bordered hover className="tbl">
                <thead>
                    <tr>
                    {
                        ASSIGNMENT_LABELS.map((label) => {
                            return <th>{label}</th>
                        })
                    }
                    </tr>
                </thead>
                <tbody>
                    {
                    this.state.patients.map((patient) => {
                    return <tr className={(patient['uid']===this.state.editId)?this.state.savedClass:""} key={patient['uid']}>
                    {/* <td><ViewDetail name={patient['name']} patientId={patient['uid']} type="patient"/></td> */}
                    <td><Link className="nav-link" to={"/patient-details/"+patient['uid']}>{patient['name']}</Link></td>
                    <td>{STATES[patient['state']]}</td>
                    <td>
                        <Form.Control as="select" required  name="progress" onChange={this.setValue} data-id={patient['uid']} disabled={patient['uid']!==this.state.editId}>
                            {
                                Object.keys(PROGRESS).map((val) => {
                                    return <option value={val} selected={val === patient['progress']?"selected":""}>{PROGRESS[val]}</option>
                                }) 
                            }
                        </Form.Control>
                        <div className="inline-msg">{this.state.message}</div>
                    </td>
                    <td><Form.Control as="select" required  name="volunteer" onChange={this.setValue} data-id={patient['uid']} disabled={patient['uid']!==this.state.editId}>
                            <option value="">Select a value</option>
                            {
                                this.state.volunteers.map((val) => {
                                    return <option value={val['uid']} selected={val['uid'] === patient['volunteer']?"selected":""}>{val['name']}</option>
                                }) 
                            }
                        </Form.Control>
                        <div className="inline-msg">{this.state.message}</div>
                    </td>
                    <td><Form.Control as="select" required  name="doctor" onChange={this.setValue} data-id={patient['uid']} disabled={patient['uid']!==this.state.editId}>
                            <option value="">Select a value</option>
                            {
                                this.state.doctors.map((val) => {
                                    return <option value={val['uid']} selected={val['uid'] === patient['doctor']?"selected":""}>{val['name'] +" - "+ val['state']}</option>
                                }) 
                            }
                        </Form.Control>
                        <div className="inline-msg">{this.state.message}</div>
                    </td>
                    <td>
                        <Form.Control as="select" required  name="overallStatus" onChange={this.setValue} data-id={patient['uid']} disabled={patient['uid']!==this.state.editId}>
                            <option value="">Select a value</option>
                            {
                                Object.keys(OVERALL_STATUS).map((val) => {
                                    return <option value={val} selected={val === patient['overallStatus']?"selected":""}>{OVERALL_STATUS[val]}</option>
                                }) 
                            }
                        </Form.Control>
                        <div className="inline-msg">{this.state.message}</div>
                    </td>
                    <td>
                        <Form.Control as="select" required  name="status" onChange={this.setValue} data-id={patient['uid']} disabled={patient['uid']!==this.state.editId}>
                            <option value="">Select a value</option>
                            {
                                Object.keys(USER_STATUS).map((val) => {
                                    return <option value={val} selected={val === patient['status']?"selected":""}>{USER_STATUS[val]}</option>
                                }) 
                            }
                        </Form.Control>
                        <div className="inline-msg">{this.state.message}</div>
                    </td>
                    <td className="text-center">
                    {
                    (this.state.editId !== patient['uid'])?<a data-id={patient['uid']} onClick={this.editRow} href="#edit"><PencilSquare width="24" height="24" /> </a>:
                    <a data-id={patient['uid']} onClick={this.cancel} href="#cancel"> <XCircle width="24" height="24" className="text-danger" data-id={patient['uid']} /></a>
                    }
                    </td>
                    </tr>
                    })
                }
                </tbody>
            </Table>
            { 
                this.state.noData &&  <Alert variant="warning">
                There is no data with this search criteria!
                </Alert>
            }
             <div class="text-right">
            <div  style={{display:"inline-block", "vertical-align": "top"}} className="mr-3">per page: <Form.Control as="select" onChange={this.setPerPage} style={{display:"inline-block",width:"60px"}}>
                {
                    PER_PAGE.map((val) => {
                    return <option value={val} selected={val === this.state.perPage}>{val}</option>
                    }) 
                }
            </Form.Control></div>
            <ReactPaginate containerClassName={'pagination'}
                subContainerClassName={'pages pagination'} 
                activeClassName={'active'}
                onPageChange={this.pageChanged}
                itemsCountPerPage={this.state.perPage}
                totalItemsCount={this.state.patientCount}
                pageRangeDisplayed={3}
                activePage={this.state.activePage}
                pageCount={Math.ceil(this.state.patientCount/this.state.perPage)}
                />
                </div>
        </Container>);
    }
}

export default compose(withFirebase)(Assignment);

