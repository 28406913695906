import React from 'react';
import { Modal } from 'react-bootstrap'
import PatientDetail from '../Patient/PatientDetail';
import VolunteerDetail from '../Register/VolunteerDetail';
const DetailModal = (props) => {
    return <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="detail-modal"
  >
    <Modal.Header closeButton>
      {props.type === "patient"?<PatientDetail {...props}/>:<VolunteerDetail {...props}/> }
    </Modal.Header>
  </Modal>
}
 
const ViewDetail = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
      return (<>
       <a href="#details" onClick={() => setModalShow(true)}>{props.name}</a>
      <DetailModal
        show={modalShow}
        {...props}
        onHide={() => setModalShow(false)}
      />
      </>)
}
export default ViewDetail;