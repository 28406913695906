import React from 'react';
export const PAITENT_LABELS = {
    formTitle: "Patient Information",
    patientTitle: "Patient Details",
    name: "Name",
    age: "Age",
    gender: "Gender",
    mobile: "Mobile",
    email: "Email",
    area: "Area/Village",
    city: "City/District",
    state: "State",
    symptomsTitle:"COVID-19 Symptoms",
    briefSym:"Brief about your Symptoms",
    briefProb:"History of any other medical problems",
    emergencyTitle: "Emergency Contact/Family Member Details",
    contName: "Name",
    relation: "Relation",
    contMobile: "Mobile",
    contEmail: "Email",
    currentMedication: "Current Medication (if any)",
    allergies: "Allergies (if any)",
    temperature: "Temperature",
    oxygenSaturation: "Oxygen Saturation",
    date: "Date of Application"
};

export const REGISTER_LABELS = {
    name: "Name",
    age: "Age",
    gender: "Gender",
    mobile: "Mobile",
    email: "Email",
    specialization: "Area of Specialization",
    institute: "Name of college/Hospital",
    hospital: "Name of the Hospital",
    college: "Medical College",
    regId: "Registration ID",
    degree: "Education Qualification",
    area: "Area/Village",
    city: "City/District",
    state: "State",
    consent: "I hereby confirm that I am above 18 years of age.",
    doctorTitle: "Doctor Sign up",
    volunteerTitle: "Volunteer Registration"
}

export const ASSIGNMENT_LABELS = [
     "Patient Name",
     "State",
     "Progress",
     "Assigned Volunteer",
    "Consulted Doctor",
     "Overall Patient Status",
    "Active/Inactive",
    "Action",
]

export const ADDUSER_LABELS = [
     "Name",
     "Type",
     "Email",
     "State",
     "Password",
     "Status",
     "Action",
]

export const DONOR_LABELS = {
    formTitle: "Donor Information",
    patientTitle: "Donor Details",
    name: "Name",
    age: "Age",
    gender: "Gender",
    mobile: "Mobile",
    email: "Email",
    area: "Area/Village",
    city: "City/District",
    state: "State",
    bloodGroup: "Blood Group",
    isPositive:" Did you test positive recently for COVID-19?",
    positiveDate:"Date of test",
};

export const DONOR_TABLE_LABELS = {
    name: "Name",
    age: "Age",
    gender: "Gender",
    phone: "Mobile",
    email: "Email",
    area: "Area/Village",
    city: "City/District",
    state: "State",
    bloodGroup: "Blood Group",
    isPositive:"Positive",
    positiveDate:"Positive Date"
}

export const TERMS_AND_CONDITIONS = {
    patient: <div><p>Shwas (Upiri) is a voluntary organization.</p>
    <p>The goal of the organization is to help patient’s access COVID care. </p>
    <p>The aim of this organization is to save lives in this pandemic.</p>
    <p>It acts as a liaison between patients and providers.</p>
    <p>Due to limited resources, the program may not be able to provide comprehensive care but will help to coordinate the care with providers in the local area.</p>
    <p>Shwas(Upiri) may not be able to reach out to all the people due to limited volunteers and healthcare professionals. Patients are advised to seek help from doctors in their locality without waiting and go to the nearest COVID hospital in case of emergency.</p></div>,

    Volunteer: <div><p>Shwas (Upiri) is a voluntary organization.</p>
    <p>The goal of the organization is to help patient’s access COVID care.</p>
    <p>The aim of this organization is to save lives in this pandemic.</p>
    <p>It acts as a liaison between patients and providers.</p>
    <p>Due to limited resources, the program may not be able to provide comprehensive care but will help to coordinate the care with providers in the local area.</p>
    <p>Shwas(Upiri) may not be able to reach out to all the people due to limited volunteers and healthcare professionals. Patients are advised to seek help from doctors in their locality without waiting and go to the nearest COVID hospital in case of emergency.</p>
    <p>As a volunteer, I will perform my duties without seeking any monetary compensation, conform to the code of conduct of the organizations, serve with dedication, sincerity, and maintain patient confidentiality.</p>
    <p>I acknowledge that the information I have provided in the application is accurate to the best of my knowledge.</p></div>,

    Doctor: <div><p>Shwas (Upiri) is a voluntary organization.</p>
    <p>The goal of the organization is to help patient’s access COVID care.</p>
    <p>The aim of this organization is to save lives in this pandemic.</p>
    <p>It acts as a liaison between patients and providers.</p>
    <p>Due to limited resources, the program may not be able to provide comprehensive care but will help to coordinate the care with providers in the local area.</p>
    <p>Shwas(Upiri) may not be able to reach out to all the people due to limited volunteers and healthcare professionals. Patients are advised to seek help from doctors in their locality without waiting and go to the nearest COVID hospital in case of emergency.</p>
    <p>As a Doctor, I will provide services to my patient to the best of my abilities, conform to the code of conduct of the organizations, serve with dedication, sincerity, and maintain patient confidentiality.</p>
    <p>I acknowledge that the information I have provided in the application is accurate to the best of my knowledge.</p></div>,

    donor: <div><p>Shwas (Upiri) is a voluntary organization.</p>
    <p>The goal of the organization is to help patient’s access COVID care.</p>
    <p>The aim of this organization is to save lives in this pandemic.</p>
    <p>It acts as a liaison between patients and providers.</p>
    <p>Due to limited resources, the program may not be able to provide comprehensive care but will help to coordinate the care with providers in the local area.</p>
    <p>Shwas(Upiri) may not be able to reach out to all the people due to limited volunteers and healthcare professionals. Patients are advised to seek help from doctors in their locality without waiting and go to the nearest COVID hospital in case of emergency.</p>
    <p>As a donor, I will donate plasma without seeking any monetary compensation, conform to the code of conduct of the organizations, serve with dedication, sincerity, and maintain patient confidentiality.</p>
    <p>I acknowledge that the information I have provided in the application is accurate to the best of my knowledge.</p></div>
}