import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { PAITENT_LABELS } from '../../constants/labels'
import { SYMPTOMS, STATES, GENDER, PREFIXES } from '../../constants/fields'
import { withFirebase }  from '../Firebase'
import { compose } from 'recompose';
import Terms from '../Static/Terms';
import {thenMessage, catchMessage} from '../../utils'

const INITIAL_STATE = {
    "name": "",
    "age": "",
    "gender": "",
    "phone": "",
    "email": "",
    "area":"",
    "city":"",
    "state":"",
    "symptoms": [],
    "symptomsSummary": "",
    "medicalHistory": "",
    "contact": {
        "name": "",
        "number": "",
        "email": "",
        "relation":"",
        "prefix": "+91"
    },
    "terms": false,
    "conversation": null,
    "prefix": "+91"
  };

class Patient extends Component {

    constructor(props) {
        super(props);
        this.data = { ...INITIAL_STATE };
        this.state = {disabled: false, groupReq: true};
    }

    componentDidMount() {
        this.props.firebase.getPatientCount().once('value', snapshot => console.log("Count: ", snapshot.val()));
    }

    setValue =  evt => {
        this.data[evt.target.name] = evt.target.value;
    }

    handleChecked =  evt => {
        this.data[evt.target.name] = !this.data[evt.target.name];
    }

    setContact =  evt => {
        this.data.contact[evt.target.name] = evt.target.value;
    }

    setSymptoms = evt => {
        let symptoms = this.data["symptoms"];
        (symptoms.indexOf(evt.target.value) !== -1)?symptoms.splice(symptoms.indexOf(evt.target.value), 1):symptoms.push(evt.target.value);
        const groupReq = ((symptoms.length === 0)?true:false);
        this.setState({"groupReq":groupReq})
        this.data["symptoms"] =  symptoms;
    }

    createPatientRecord = async() => {
        let res = await this.props.firebase.createPatient(this.data)
        let resp = thenMessage(res);
        this.setState(resp);
        setTimeout(()=> this.props.history.push("/"), 6000);
    }

    handleSubmit = async evt => {
        evt.preventDefault();
        const isUnique = await this.props.firebase.checkForEmail(this.data.email);
        if(!isUnique) {
            alert("Email already exits!")
        }
        if(document.getElementById("myForm").checkValidity() && isUnique) {
            this.data.createdAt = this.props.firebase.getSessionDate();
            this.data.phone = this.data.prefix+this.data.phone;
            this.data.contact.phone = this.data.contact.prefix+this.data.contact.phone;
            this.setState({disabled:true});
            this.createPatientRecord();
        }
    }

    render() {
        return (<Container>
            {this.state.showAlert && <Alert variant={this.state.variant}><b>{this.state.message}</b></Alert>}
            <Row>
                <Col className="text-center"><h2>{PAITENT_LABELS.formTitle}</h2></Col>
            </Row>
            <Form className="my-form" onSubmit={this.handleSubmit} id="myForm">
                <Row>
                    <Col>
                        <b>{PAITENT_LABELS.patientTitle}</b>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.name}</Form.Label>
                            <Form.Control type="text" required  name="name" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.age}</Form.Label>
                            <Form.Control type="number" required  name="age" onChange={this.setValue} min={0} max={120}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.gender}</Form.Label>
                            <Form.Control as="select" required  name="gender" onChange={this.setValue}>
                            <option value="">Select a value</option>
                            {
                                Object.keys(GENDER).map((val) => {
                                    return <option value={val}>{GENDER[val]}</option>
                                }) 
                            }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.mobile}</Form.Label>
                            <div class="mobile-block">
                            <Form.Control as="select" required name="prefix" onChange={this.setValue} className="prefix">
                                {
                                    PREFIXES.map((val) => {
                                        return <option value={val}>{val}</option>
                                    }) 
                                }
                            </Form.Control>
                            <Form.Control type="tel" required name="phone" onChange={this.setValue} pattern="^\d{1,14}$"/>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.email}</Form.Label>
                            <Form.Control type="email" required  name="email" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.area}</Form.Label>
                            <Form.Control type="text" required name="area" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.city}</Form.Label>
                            <Form.Control type="text" required  name="city" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.state}</Form.Label>
                            <Form.Control as="select" required  name="state" onChange={this.setValue}>
                            <option value="">Select a state</option>
                            {
                                Object.keys(STATES).map((state) => {
                                    return <option value={state}>{STATES[state]}</option>
                                }) 
                            }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b className="req-label">{PAITENT_LABELS.symptomsTitle}</b>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    {SYMPTOMS.map((field) => {
                        return <Col lg={3} md={3}>
                            <Form.Check
                                name="symptoms"
                                type="checkbox"
                                required={this.state.groupReq}
                                onChange={this.setSymptoms}
                                label={field}
                                value={field}
                            />
                        </Col>
                    })
                    }
                </Row>
                <p></p>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{PAITENT_LABELS.allergies}</Form.Label>
                            <Form.Control type="text"  name="allergies" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>{PAITENT_LABELS.temperature}</Form.Label>
                            <Form.Control type="text"  name="temperature" onChange={this.setValue} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label>{PAITENT_LABELS.oxygenSaturation}</Form.Label>
                            <Form.Control type="text"  name="oxygenSaturation" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className="area">{PAITENT_LABELS.briefSym}</Form.Label>
                            <Form.Control as="textarea" rows="3"  name="symptomsSummary" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className="area">{PAITENT_LABELS.briefProb}</Form.Label>
                            <Form.Control as="textarea" rows="3"  name="medicalHistory" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>{PAITENT_LABELS.currentMedication}</Form.Label>
                            <Form.Control as="textarea" rows="3" name="currentMedication" onChange={this.setValue}/>
                        </Form.Group>
                    </Col>
                </Row>  
                <Row>
                    <Col>
                        <b>{PAITENT_LABELS.emergencyTitle}</b>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group  className="req">
                            <Form.Label>{PAITENT_LABELS.contName}</Form.Label>
                            <Form.Control type="text" required  name="name" onChange={this.setContact}/>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group  className="req">
                            <Form.Label>{PAITENT_LABELS.relation}</Form.Label>
                            <Form.Control type="text" required  name="relation" onChange={this.setContact}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.contMobile}</Form.Label>
                            
                            <div class="mobile-block">
                            <Form.Control as="select" required name="prefix" onChange={this.setContact} className="prefix">
                                {
                                    PREFIXES.map((val) => {
                                        return <option value={val}>{val}</option>
                                    }) 
                                }
                            </Form.Control>
                            <Form.Control type="tel" required name="phone" onChange={this.setContact} pattern="^\d{1,14}$"/>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="req">
                            <Form.Label>{PAITENT_LABELS.contEmail}</Form.Label>
                            <Form.Control type="email" required  name="email" onChange={this.setContact}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="req">
                        <Form.Check
                                required
                                type="checkbox"
                                label={<Terms form="patient" />}
                                value="agreed"
                                name="terms"
                                onChange={this.handleChecked}
                            />
                    </Col>
                </Row>
                <div className="text-center mb-3">
                    <Button variant="secondary" type="button" className="mr-2" onClick={(e) => this.props.history.push("/") }>
                            Cancel
                    </Button>
                    <Button variant="primary" type="submit" disabled={this.state.disabled}>
                        Submit
                    </Button>
                </div>
            </Form>
        </Container>);
    }
}

export default compose(withFirebase)(Patient);