import React from 'react';
import { Link } from 'react-router-dom';
import SignOutButton from '../SignOut';
import ROUTES, {ADDUSER_ROUTE, ASSIGNMENT_ROUTE, DONORS_ROUTE, PROTOCOL_ROUTE} from '../../constants/routes';
import { AuthUserContext } from '../Session';
import {Navbar, Nav, Container} from 'react-bootstrap';
import ls from 'local-storage'
 
const Navigation = (props) => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        <Navbar bg="light" expand="lg" className="mb-3 navabar">
        <Container>
        <Navbar.Brand href="/" class='nh'>Shwas (Upiri)</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Link className="nav-link" to={ROUTES.home.path}>{ROUTES.home.label}</Link>
            <Link className="nav-link" to={ROUTES.mission.path}>{ROUTES.mission.label}</Link>
            <Link className="nav-link" to={ROUTES.about.path}>{ROUTES.about.label}</Link>
            <Link className="nav-link" to={ROUTES.covid.path}>{ROUTES.covid.label}</Link>
            <Link className="nav-link" to={ROUTES.gallery.path}>{ROUTES.gallery.label}</Link>
            { !authUser && 
              <Link className="nav-link" to={ROUTES.login.path}>{ROUTES.login.label}</Link>
            }
            
            { (authUser && props.isAdmin) && 
              <Link className="nav-link" to={ADDUSER_ROUTE}>Users</Link>
            }
            { (authUser && props.isAdmin) && 
              <Link className="nav-link" to={ROUTES.addProtocol.path}>{ROUTES.addProtocol.label}</Link>
            }
            { authUser && !ls('isPatient') && 
              <Link className="nav-link" to={PROTOCOL_ROUTE}>Protocol</Link>
            }
            { authUser && !ls('isPatient') && 
              <Link className="nav-link" to={ASSIGNMENT_ROUTE}>Assign</Link>
            }
            { authUser && !ls('isPatient') &&
              <Link className="nav-link" to={DONORS_ROUTE}>Donors</Link>
            }
            { authUser && ls('isPatient') &&
              <Link className="nav-link" to={'/patient-details/'+ls('id')}>My Details</Link>
            }
            { authUser && 
              <SignOutButton />
            }
          </Nav>
        </Navbar.Collapse>
        </Container>
  </Navbar>
  }
    </AuthUserContext.Consumer>
  </div>
);
 
export default Navigation;