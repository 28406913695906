import React from 'react';
import {Container} from 'react-bootstrap'
const Mission = () => {
    return <Container >
        <h1 className="text-center mb-5">Mission</h1>
        <p className="text-justify">Corona Virus disease (COVID-19) originated in China and quickly spread to all continents affecting most countries in the world. World Health Organization declared COVID-19 a pandemic on 11th March 2020. COVID 19 is similar to Flu (influenza) pandemic in 1918-1920. Symptoms include fever, body aches, headache, cough, difficulty breathing, sore throat, loss of smell, nausea, vomiting, diarrhea, etc. 95% of patients develop symptoms within 2-7 days, 97% within 5-14 days. As of 1st August 2020, nearly 17 lakh people were infected with COVID19 and 36000 people lost their lives in Bharat. With the virus spreading across the country there is fear and uncertainty due to lack of treatment and availability of a vaccine.</p>
        <p className="text-justify">The Spanish flu, also known as the 1918 flu pandemic, infected one-third of the world’s population and killed nearly 5 crore people. Bharat was the worst affected country in the world with 5 crore people infected and nearly 1.2 crore deaths. One hundred years later, the situation is not significantly different from the one in 1918.</p>
        <p className="text-justify">Shwas(Upiri), a virtual COVID treatment program was started with a mission to provide advice or care to patients due to limited healthcare resources and  shortage of beds in hospitals across Bharat. Doctors across the country have joined hands to provide treatment with the human touch. The aim of Shwas(Upiri) is to coordinate care for COVID positive patients or those with COVID symptoms. Doctors will give advice or formulate a treatment regimen and essential equipment for patients that are cheap and conforming to the standard of care.</p>
        <p className="text-justify">Shwas(Upiri) program will reach out to people across all sections of the society in the entire country. Due to a shortage of doctors and volunteers, it is not always possible to give advise or provide care to every patient who has requested help. In the meantime, patients are advised to seek help from their local doctors and the nearest hospital without waiting. In cases of emergency, the patients are advised to go to the nearest hospital without any delay.</p>
    </Container>;
}
 
export default Mission;